<template>
    <div class="cloud-import">
        <div
            class="cloud-import__interval-container"
        >
            <BaseInput
                class="cloud-import__interval-button"
                :value="clearFromDate"
                type="datetime-local"
                @onInput="onInputFromDate"
            />
            <BaseInput
                class="cloud-import__interval-button"
                v-model="clearToDate"
                type="datetime-local"
                @onInput="onInputToDate"
            />
            <BaseButton
                view="simple"
                :disabled="!isIntervalButtonActive"
                form="square"
                @click="onClearInterval"
            >Сбросить интервал</BaseButton>
        </div>

        <AppTable
            primaryKey="id"
            :columns="columns"
            :rows="rows"
            class="cloud-import__table"
            @lazyload="onLazyload"
            @search="onSearch"
            @sort="onSort"
        >
            <template v-slot:status="scopeData">
                <div class="cloud-import__table-buttons">
                    {{getStatusLabel(scopeData)}}
                </div>
            </template>

            <template v-slot:type="scopeData">
                <div>
                    {{getTypeLabel(scopeData)}}
                </div>
            </template>

            <template v-slot:buttons="scopeData">
                <div class="cloud-import__table-buttons">
                    <BaseButton
                        :view="'third'"
                        form="square"
                        @click="onViewLogs(scopeData)"
                    >
                        История
                    </BaseButton>
                    <BaseButton
                        v-for="(status, index) in scopeData.row.sheet_statuses"
                        :key="index"
                        :view="status === 1 ? 'secondary' : 'primary'"
                        form="square"
                        @click="onEditSettings(scopeData, index)"
                    >
                        {{index + 1}}
                    </BaseButton>
                </div>
            </template>

            <template
                v-for="(column, index) in ['created_at', 'updated_at', 'uploaded_at']"
                v-slot:[column]="scopeData"
            >
                <div :key="index">
                    <div>{{getFormattedDate(column, scopeData)}}</div>
                    <BaseButton
                        v-if="column === 'uploaded_at' && scopeData.row[column] !== null"
                        view="simple"
                        form="oval"
                        size="small"
                        @click="onClearDate(scopeData)"
                    >Сбросить</BaseButton>
                    <div >

                    </div>
                </div>
            </template>
        </AppTable>
    </div>
</template>

<script>
import AppTable from '@/components/Table2/Table.vue';
import ServiceCloudImport from "@/services/ServicesCloudImport/ServiceCloudImport";
import UtilDate from '@/utils/UtilDate';
import BaseButton from "@/components/Base/BaseButton.vue";
import BaseInput from "@/components/Base/BaseInput.vue";
export default {
	name: "PageCloudImport",
	components: {
        BaseInput,
        AppTable,
        BaseButton
	},
    /**
     * Входные данные компонента
     */
    props: {
    },
	data() {
		return {
            columns: [],
            rows: [],
            page: null,
            pageCount: null,
            filterParams: {},
            sortParam: null,
            clearFromDate: "",
            clearToDate: ""
		}
	},
	computed: {
        isIntervalButtonActive() {
            return this.clearToDate !== "" && this.clearFromDate !== "";
        }
	},
    beforeMount() {
        this.page = 1;
    },
    created() {

	},
	methods: {
        onLazyload(){
            if(this.page < this.pageCount){
                this.page++;
            }
        },

        getStatusLabel(scopeData){
            const {row = {}} = scopeData;
            const {status = 0} = row;
            return status ? "Вкл" : "Откл";
        },

        getTypeLabel(scopeData){
            const {row = {}} = scopeData;
            const {type = "FILE"} = row;
            switch (type) {
                case "FILE":
                    return "Файл";
                case "FOLDER":
                    return "Папка";
                default:
                    return "";
            }
        },

        getFormattedDate(prop, scopeData){
            return UtilDate.getLocaleDateWithTime(scopeData.row[prop]);
        },

        onClearDate(scopeData) {
            const {row = {}} = scopeData;
            const {id = 0} = row;
            ServiceCloudImport.clearSetting(id, null,() => {
                const itemIndex = this.rows.findIndex(item => Number(item.id) === Number(id));
                if(itemIndex !== -1){
                    const targetItem = {...this.rows[itemIndex], uploaded_at: null};
                    this.rows.splice(itemIndex, 1, targetItem);
                }
            })
        },

        onSearch(value) {
            this.filterParams = value;
            this.page = null;
        },

        onSort(sortData) {
            const {key = "", type = ""} = sortData
            this.sortParam = type === 'asc' ? `-${key}` : key;
            this.page = null;
        },

        onInputFromDate(value) {
            this.clearFromDate = value;
        },

        onInputToDate(value) {
            this.clearToDate = value;
        },

        onClearInterval() {
            if(!this.isIntervalButtonActive){
                return;
            }

            ServiceCloudImport.clearSettingInterval(this.clearFromDate, this.clearToDate, () => {
                this.page = null
            })
        },

        onViewLogs(scopeData) {
            const {row = {}} = scopeData;
            const {id: settingId = 0} = row;

            window.open('/cloud-import/setting-logs?settingId=' + settingId, '_blank');
        },

        onEditSettings(scopeData, sheetIndex) {
            const {row = {}} = scopeData;
            const {id: settingId = 0} = row;

            window.open('/cloud-import/edit-settings?settingId=' + settingId + '&sheetIndex=' + sheetIndex, '_blank');
        }
	},
	watch: {
        page() {
            if(this.page === null){
                this.rows = [];
                this.page = 1;
                return;
            }

            let queryParams = {...this.filterParams};
            if(this.sortParam){
                queryParams = {...queryParams, sort: this.sortParam}
            }

            ServiceCloudImport.list(this.page, queryParams, (result) => {
                const {pageCount = 0, items = [], columns = []} = result;
                this.rows = [...this.rows, ...items];
                this.columns = columns;
                this.pageCount = pageCount;
            }, () => {

            });
        }
	}
}
</script>

<style lang="scss" scoped>
.cloud-import {
    height: 100%;
    overflow-y: auto;
    padding: 20px;

    &__interval-container{
        display: flex;
        margin-bottom: 20px;
    }

    &__interval-button {
        padding: 5px;
        margin: 5px;
    }

    &__table {
        width: 100%;
        height: auto;
        max-height: 100vh;
    }

    &__table-buttons{
        display: flex;
        overflow-x: auto;
    }

    &__table-buttons .btn{
        margin: 2px;
        padding: 6px;
    }
}
</style>
